
import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

export default extendTheme({
    breakpoints: createBreakpoints({
        sm: "30em", md: "48em", lg: "62em", xl: "80em"
    }),
    fonts: {
        body: "'Helvetica Neue', arial",
    },
    colors: {
        primary: '#2A82F0',
        primaryIndie: '#1561C1',
        secondary: '#183560',
        secondaryIndie: '#0D2241',
        danger: '#E83A3A',
        dangerIndie: '#CB2020',
        success: '#3EC34D',
        successIndie: '#20892C',
    },
});